@import './keyframes.scss';

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

.sub-page-header {
  display        : flex;
  justify-content: center;
  padding        : 1rem;
  color          : var(--panda-primary-dark);
  user-select    : none;
  font-weight    : 600;
  font-size      : 1.2rem;
}

thead {
  background-color: var(--panda-primary-main);
  // box-shadow      : 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  th {
    font-weight: 500;
    font-size: 1rem !important;
    color: var(--panda-primary-light) !important;
  }
}

.color-danger {
  color: brown !important;
}

.flex-column {
  display       : flex;
  flex-direction: column;
}

.width-100 {
  width: 100%!important;
}

.height-50 {
  height:45px!important;
}

.mx-1 {
  margin: 0 1rem;
}

.display-none {
  display: none;
}

.ml-1 {
  margin-left: 1rem !important;
}
.back-gray {
  background-color: gray;
}

.default-table-header-bg {
  background-color: #ECEFF9;
}

.search-header{
  margin-top: 2rem;
  padding: 1rem;
  .search-bar {

  }
  .create-btn {
    float: right;
    display: flex;
    align-items: inherit;
    justify-content: end;
  }
}

.inline-items {
  display: flex;
  align-items: center;
}
.gray {
  color: gray;
  cursor: pointer;
}

.justify-content-center {
  justify-content: center;
} 

.d-flex {
  display: flex;
}

.mb-2 {
  margin-bottom: 2rem!important;
}

.mb-1 {
  margin-bottom: 1rem!important;
}

.border-radius-15 {
  border-radius: 15px;
}

.font-size-15 {
  font-size: 15px;
}